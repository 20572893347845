import React from "react"
import { useState } from "react";

import "./layout.scss"
import "./typography.scss"
import "./global.scss"
import "./settings/functions.scss"
import "./settings/mixins.scss"
import "./settings/variables.scss"
import {Helmet} from "react-helmet";
import { Link } from "gatsby"
import logo1 from '../assets/logo-af.svg';
import hamburger from '../assets/hamburger-icon.svg';


export default function Layout({children}) {
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <div className="content">
    <Helmet>
      <script type="text/javascript"  src="app.js" charset="utf-8"></script>
      <title>Design/UX/Illustration & Design Direction | Ali Felski Design</title>
      <meta name="description" content="With nearly 15 year of experience, I create highly usable experiences for companies large and small." />
    </Helmet>
    <div className="home-header">
      <div className="row">
        <div className="header-content-wrapper flex-break-md">
          <div className="nav-item-wrapper-left2 nav-hide-mobile">
            <Link className="nav-item" to="/portfolio/">Portfolio</Link>
          </div>
          <div className="nav-item-wrapper-left nav-hide-mobile">
            <Link className="nav-item" to="/about/">About</Link>
          </div>
          <div className="nav-item-wrapper">
            <Link className="nav-item" to="/">
              <img className="logo" src={logo1} alt="Ali Felski Design" />
            </Link>
          </div>
          <div className="nav-item-wrapper-right nav-hide-mobile">
            <a className="nav-item" target="_blank" href="https://www.linkedin.com/in/alexandra-felski-6626464/">Resume</a>
          </div>
          <div className="nav-item-wrapper-right nav-hide-mobile">
            <a className="nav-item" href="mailto:alifelski@gmail.com">Contact</a>
          </div>
          <div className="mobile-version">
            <div className="mobile-nav">
              <div className={`mobile-nav-menu ${isActive ? "closed" : "open"}`}>
                <img src={hamburger} onClick={handleToggle} alt="Hambuger menu" />
                <div className="mobile-nav-wrapper">
                  <ul className="mobile-nav-list">
                    <li><Link className="nav-item" to="/portfolio/">Portfolio</Link></li>
                    <li><Link className="nav-item" to="/about/">About</Link></li>
                    <li><a className="nav-item" href="https://www.linkedin.com/in/alexandra-felski-6626464/">Resume</a></li>
                    <li><a className="nav-item" href="mailto:alifelski@gmail.com">Contact</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      {children}
    </div>
  )
}
